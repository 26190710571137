import React, { Fragment } from 'react';
//import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import QualitySectionWrapper from './qualitySection.style';
import FeatureImage from 'common/src/assets/image/archerhealth/feature/Home-Adapt-with-Archer-Health-min.png';

const QualitySection = ({
  row,
  col,
  title,
  featureCol,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  featureTitle,
  featureDescription,
  iconStyle,
}) => {
  /*const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        qualityFeature {
          description
          icon
          image
          id
          title,
          link
        }
      }
    }
  `);*/
//^ above comes from /common/src/data/Agency/index.json
  return (
    <QualitySectionWrapper id="qualitySection">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col} {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="Adapt with Archer Health: Comprehensive employee 
                  health & wellness services"
                  {...title}
                />
              }
              description={
                <Text
                  content="Our services provide peace of mind, flexibility, and the highest standard of care at a price that makes sense for your business."
                  {...description}
                />
              }
            />
          </Box>
        </Box>

        <Box className="row" {...row} {...textArea}>
          <Box
              className="col"
              {...featureCol}
              key={`quality_feature-1`}
            >
              <FeatureBlock
                icon={<i className={`feature.icon`} />}
                iconPosition="left"
                iconStyle={iconStyle}
                image={`/archerhealth/feature/Icon-Onsite-Clinic.png`}
                title={<Heading content={`Onsite Clinics`} {...featureTitle} />}
                description={
                  <Text content={`Onsite clinics for healthcare you can count on: when and where you need it most`} {...featureDescription} />
                }
                button={<Fragment>
                        <a href={`/onsite-clinics`}>Learn More &raquo;</a>                         
                        {/*<img src="/archerhealth/feature/arrow.png" alt="learn more" />*/}
                        </Fragment>}
              />
            </Box>
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-1`}
            >
              <FeatureBlock
                icon={<i className={`feature.icon`} />}
                iconPosition="left"
                iconStyle={iconStyle}
                image={`/archerhealth/feature/Icon-ArcherRx.png`}
                title={<Heading content={`Archer RX`} {...featureTitle} />}
                description={
                  <Text content={`
                  Integrated, convenient onsite pharmacies for reduced costs and increased medication adherence`} {...featureDescription} />
                }
                button={<Fragment>
                        <a href={`/Archer-RX`}>Learn More &raquo;</a> 
                        </Fragment>}
              />
            </Box>
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-1`}
            >
              <FeatureBlock
                icon={<i className={`feature.icon`} />}
                iconPosition="left"
                iconStyle={iconStyle}
                image={`/archerhealth/feature/Icon-Virtual-Care.png`}
                title={<Heading content={`Virtual Care`} {...featureTitle} />}
                description={
                  <Text content={`Round the clock telemedicine care at no additional cost`} {...featureDescription} />
                }
                button={<Fragment>
                        <a href={`/virtual-care`}>Learn More &raquo;</a> 
                        </Fragment>}
              />
            </Box>
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-1`}
            >
              <FeatureBlock
                icon={<i className={`feature.icon`} />}
                iconPosition="left"
                iconStyle={iconStyle}
                image={`/archerhealth/feature/Icon-Latest-Technology.png`}
                title={<Heading content={`Latest Technology`} {...featureTitle} />}
                description={
                  <Text content={`Industry-leading tools embedded into your healthcare solution to ensure security, accessibility, and convenience`} {...featureDescription} />
                }
                button={<Fragment>
                        <a href={`/virtual-care`}>Learn More &raquo;</a> 
                        </Fragment>}
              />
            </Box>
          {/*
          {Data.agencyJson.qualityFeature.map((feature, index) => (
            <Box
              className="col"
              {...featureCol}
              key={`quality_feature-${index}`}
            >
              <FeatureBlock
                icon={<i className={feature.icon} />}
                iconPosition="left"
                iconStyle={iconStyle}
                image={feature.image}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
                button={<Fragment>
                        <a href={feature.link}>Learn More</a> 
                        <img src="/archerhealth/feature/arrow.png" alt="learn more" />
                        </Fragment>}
              />
            </Box>
          ))}
          */}
        </Box>
      </Container>

      <Container fluid noGutter className="info-sec-container">
        <Box className="row" {...row} {...imageAreaRow}>
          <Box className="col" {...col} {...imageArea}>
            <Card {...imageWrapper}>
              <Fade right delay={90}>
                <Image src={FeatureImage} alt="Archer Health Features" />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container>
    </QualitySectionWrapper>
  );
};

QualitySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  featureCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
};

QualitySection.defaultProps = {
  // Quality section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Quality section iamge row default style
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  // Quality section col default style
  col: {
    pr: '15px',
    pl: '15px',
  },
  // Quality feature col default style
  featureCol: {
    width: [1, 1, 1 / 2],
    pr: '15px',
    pl: '15px',
  },
  // Quality section text area default style
  textArea: {
    width: [1, '100%', '100%', '70%', '64%'],
  },
  // Quality section image area default style
  imageArea: {
    width: [1, '100%', '100%', '30%', '38%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  // Quality section image wrapper default style
  imageWrapper: {
    boxShadow: 'none',
    width: '100%',
  },
  // Quality section title default style
  title: {
    fontSize: ['26px', '26px', '32px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#3D636C',
    letterSpacing: '-0.010em',
    mb: '33px',
  },
  // Quality section description default style
  description: {
    fontSize: '16px',
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '33px',
  },
  // feature icon default style
  iconStyle: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    //bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#ffffff',
    overflow: 'hidden',
    mt: '6px',
    mr: '22px',
    flexShrink: 0,
  },
  // feature title default style
  featureTitle: {
    fontSize: ['20px', '22px'],
    fontWeight: '500',
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.84',
    color: '#3D636C',
  },
};

export default QualitySection;
