import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/src/theme/agency';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/Agency/agency.style';
//import Navbar from '../containers/Agency/Navbar';
import Navbar from '../containers/AppClassic/Navbar';
import BannerSection from '../containers/Agency/BannerSection';
import AboutUsSection from '../containers/Agency/AboutUsSection';
import Testimonial from '../containers/Agency/Testimonial';
import QualitySection from '../containers/Agency/QualitySection';
import Footer from '../containers/Agency/Footer';
//import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';
import PartnerHistory from '../containers/Agency/PartnerHistory';
import Newsletter from '../containers/Agency/Newsletter';
import OnsiteBenefits from '../containers/Interior/OnsiteBenefits';
import OnsiteBullets from '../containers/Interior/OnsiteBullets';
//import WorkHistory from '../containers/Agency/WorkHistory';
//import BlogSection from '../containers/Agency/BlogSection';
//import TestimonialSection from '../containers/Agency/TestimonialSection';
//import TeamSection from '../containers/Agency/TeamSection';
//import VideoSection from '../containers/Agency/VideoSection';
//import FaqSection from '../containers/Agency/FaqSection';
//import NewsletterSection from '../containers/Agency/NewsletterSection';
//import FeatureSection from '../containers/Agency/FeatureSection';

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Employee Health Services for Employers" description="Archer Health provides onsite wellness & employee health programs. Invest in  your people while reducing your overall healthcare spend. Contact us to get started!" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          {/*
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          */}
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <BannerSection />
          <PartnerHistory />
          {/* defined in src/data/Agency/index.json */}
          <QualitySection />          
          {/* defined in src/data/Agency/index.json */}          
          <AboutUsSection />
          {/* defined in src/data/AppModern/index.json */}
          <OnsiteBenefits />
          <OnsiteBullets />
          <Testimonial />
          <Newsletter />
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};