import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
//import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/Home-Case-Study-1-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(
        relativePath: { eq: "image/archerhealth/feature/Home-Case-Study-3-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(
        relativePath: { eq: "image/archerhealth/feature/Home-Case-Study-2-min.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 505, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyJson {
        aboutus {
          id        
          title
          image
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Image
                  fluid={
                    (Data.image1 !== null) | undefined
                      ? Data.image1.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Case Study Image"
                />
              </Fade>
              <Fade left delay={60}>
                <Image
                  fluid={
                    (Data.image3 !== null) | undefined
                      ? Data.image3.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Case Study Image"
                />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <Image
                  fluid={
                    (Data.image2 !== null) | undefined
                      ? Data.image2.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Case Study Image"
                />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="We're helping companies like yours elevate the employee health experience"
                  {...title}
                />
              }
              // description={
              //   <Text
              //     content="src/containers/agency/aboutUsSection/ Some hardworking People are Working Day and Night to provide you highly scalable product . "
              //     {...description}
              //   />
              // }
              description={
                <div className="archer-health-about-us">
                  <h3>Client</h3>
                  <p style={{marginBottom: '32px'}}>Self-insured Industrial Truck Dealership and Repair Firm with 200 Employees in Nashville, TN</p>

                  <h3>Engagement</h3>
                  <p style={{marginBottom: '32px'}}>Bi-weekly onsite clinic, telemedicine, onsite pharmacy</p>

                  <h3>Results</h3>
                  <p style={{marginBottom: '32px'}}>12% reduction in company's PEPM medical &amp; pharmacy spending</p>
                </div>
              }
            />
          </Box>
          <Box {...textArea}>
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}              
              iconPosition="left"
              title={<Heading content={<span><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> <strong>45% reduction</strong> in ER visits</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}              
              iconPosition="left"
              title={<Heading content={<span><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> <strong>38% reduction</strong> in Urgent Care visits</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}
              iconPosition="left"
              title={<Heading content={<span><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> <strong>35% reduction</strong> in hospital admissions</span>} {...featureTitle} />}
            />
            <FeatureBlock
              key={`feature_point-1`}
              icon={<i className={`flaticon-next`} />}              
              iconPosition="left"
              title={<Heading content={<span><span style={{color: '#19B8E8', fontSize: '20px', fontWeight: '500'}}>&raquo;</span> <strong>15% reduction</strong> in procedures</span>} {...featureTitle} />}
            />
            {/*}
            {Data.agencyJson.aboutus.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className={feature.icon} />}
                image={feature.image}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
            */}
            {/* <Button title="DISCOVER ITEM" {...btnStyle} /> */}
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '540px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',   
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
