import React, { Fragment } from 'react';
//import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
//import Rating from 'common/src/components/Rating';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { SectionHeader } from '../../appModern/appModern.style';
import SectionWrapper, { CarouselWrapper } from './testimonial.style';

const Testimonial = () => {
  /*const data = useStaticQuery(graphql`
    query {
      appModernJson {
        testimonial {
          slogan
          title
          reviews {
            id
            title
            description
            avatar
            name
            designation
            review
          }
        }
      }
    }
  `);*/
  //const { slogan, title, reviews } = data.appModernJson.testimonial;

  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 2,
    animationDuration: 700,
    breakpoints: {
      991: {
        perView: 1,
      },
    },
  };

  return (
    <SectionWrapper id="testimonial">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content="Testimonials" />
            <Heading content="Our Partners Say It Best" />
          </Fade>
        </SectionHeader>

        <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                />
              }
            >
              <Fragment>
                <GlideSlide key={`testimonial--key1`}>
                  <div className="review-card">
                    {/*<Heading as="h3" content={item.title} />*/}
                    <Text content={<Fragment><p>&quot;It is of utmost importance not just from a pure claims perspective,
                    but also a quality of life and productivity perspective, that we are
                    removing as many barriers as possible to accessing quality primary care.
                    Archer Health has served that role for many of our clients. We have
                    encountered many situations where the Archer Health team has caught a 
                    potentially very serious condition and intervened to prevent a very large claim.</p>
                      <p>
                        On the other end of the spectrum, they have also done a great job at
                        managing the care of those that are living with chronic conditions currently.
                                The financial and cultural impact that we are seeing is very impressive.&quot;</p></Fragment>} />
                    <div className="card-footer">
                      <div className="reviewer-info">
                        <div className="content">
                          <Heading as="h4" content={`Logan Young`} />
                          <Text content={`Employee Benefits Advisor, HUB`} />
                        </div>
                        {/*<Rating rating={item.review} />*/}
                        <div className="image">
                          <Image src={`/archerhealth/testimonial/logo-hub.png`} alt="HUB Logo" />
                        </div>
                      </div>

                    </div>
                  </div>
                </GlideSlide>
                <GlideSlide key={`testimonial--key2`}>
                  <div className="review-card">
                    {/*<Heading as="h3" content={item.title} />*/}
                    <Text content={<Fragment><p>&quot;Archer Health has been instrumental in dramatically 
                      decreasing the number of emergency room, urgent care and physician office visits for 
                      my client. Additionally, the ability to see a Nurse Practitioner during or after 
                      work has been an absolute hit with employees. In terms of attracting and retaining 
                      employees, it has been a differentiator for my client.&quot;</p></Fragment>} />
                    <div className="card-footer">
                      <div className="reviewer-info">
                        <div className="content">
                          <Heading as="h4" content={`Jonathan Frisch`} />
                          <Text content={`Senior Vice President, Employee Benefits Division, McGriff Insurance`} />
                        </div>
                        {/*<Rating rating={item.review} />*/}
                        <div className="image">
                          <Image src={`/archerhealth/testimonial/logo-mcgriff.png`} alt="McGriff Logo" />
                        </div>
                      </div>

                    </div>
                  </div>
                </GlideSlide>
                <GlideSlide key={`testimonial--key3`}>
                  <div className="review-card">
                    {/*<Heading as="h3" content={item.title} />*/}
                    <Text content={<Fragment><p>&quot;Archer Health has proven to be an invaluable asset to our organization. Our internal wellness scores have increased substantially since implementing and our employee usage has been through the roof. In addition, our absenteeism has decreased while engagement scores have consistently increased. I am thoroughly confident this is a direct result of utilizing Archer Health's services. The team has been an absolute pleasure to work with and this investment in our employees has truly resulted in quantifiable gains for our organization. I could not recommend their services enough.&quot;</p></Fragment>} />
                    <div className="card-footer">
                      <div className="reviewer-info">
                        <div className="content">
                          <Heading as="h4" content={`Adam Reding`} />
                          <Text content={`Director of Talent Operations, Mimeo`} />
                        </div>
                        {/*<Rating rating={item.review} />*/}
                        <div className="image">
                          <Image src={`/archerhealth/feature/Logo-Mimeo.png`} alt="Mimeo Logo" />
                        </div>
                      </div>

                    </div>
                  </div>
                </GlideSlide>
                <GlideSlide key={`testimonial--key3`}>
                  <div className="review-card">
                    {/*<Heading as="h3" content={item.title} />*/}
                    <Text content={<Fragment><p>&quot;We are very pleased with Archer Health. We’ve used them for about a year so far and plan to continue to do so.  The pricing is competitive and the service provider at our location is top-notch.  Our team really connected with the NP and it is evident she truly cares for our team.  Though COVID saw most of our team working from home, many folks were still able to see the practitioner using telehealth options.  Our team is getting the quality medical care they need and deserve and it will pay dividends for our company for years.  If you are looking for a high-quality healthcare solution for your team that will do what they say they are going to do, Archer Health is the way to go.&quot;</p></Fragment>} />
                    <div className="card-footer">
                      <div className="reviewer-info">
                        <div className="content">
                          <Heading as="h4" content={`Tiffany Wilson-Vargas`} />
                          <Text content={`Purchasing Supervisor, Kirby Building Systems`} />
                        </div>
                        {/*<Rating rating={item.review} />*/}
                        <div className="image">
                          <Image src={`/archerhealth/feature/Logo-Kirby.png`} alt="Kirby Building Systems Logo" style={{height: '100px'}} />
                        </div>
                      </div>

                    </div>
                  </div>
                </GlideSlide>
                {/*
                {reviews.map(item => (
                  <GlideSlide key={`testimonial--key${item.id}`}>
                    <div className="review-card">
                       <Heading as="h3" content={item.title} />
                      <Text content={item.description} />
                      <div className="card-footer">                        
                        <div className="reviewer-info">
                          <div className="content">
                            <Heading as="h4" content={item.name} />
                            <Text content={item.designation} />
                          </div>
                          <Rating rating={item.review} />
                          <div className="image">
                            <Image src={item.avatar} alt="Client Image" />
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </GlideSlide>
                ))}
                */}

              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Testimonial;
