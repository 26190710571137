import React from 'react';
import Fade from 'react-reveal/Fade';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionWrapper, { Container, TextWrapper } from './aboutUs.style';

const OnsiteBullets = () => {
  
  const setTitle = title => {
    return { __html: title };
  };

  return (
    <SectionWrapper id="aboutUs">
      <Container>
        
        <TextWrapper>
          <Fade right>
          <TextWrapper>
        <Heading as="h3" dangerouslySetInnerHTML={setTitle(`Easy Access to Quality Care`)}></Heading>
            <Text content={`Your busy employees might not have time to go to the doctor for their health issues. When they need help with minor health issues such as a sore throat, fever or earache, access to a nurse practitioner or physician at the workplace promotes health access. They won't have to miss work or make a special trip to get the care they need.`} />
            <br/><br/>

            <Heading as="h3" dangerouslySetInnerHTML={setTitle(`Early Health Screenings for Chronic Diseases`)}></Heading>
            <Text content={`Many Americans have chronic health conditions they don't know about. High blood pressure, high cholesterol and high blood sugar are a few common problems that can improve from early detection and treatment. When your human resources department provides access to these health screenings, your employees can be treated for their conditions before they develop serious and costly complications.`} />
            <br/><br/>

            <Heading as="h3" dangerouslySetInnerHTML={setTitle(`Improve Workplace Health and Safety`)}></Heading>
            <Text content={`Onsite health clinics improve workplace health and safety. Employees can manage their chronic conditions in a more efficient way. A person who experiences an on-the-job injury can be seen immediately. The clinician can do triage in order to determine if there's a need for a trip to the emergency department. Avoiding a costly ER trip saves both employers and employees money.`} />
        </TextWrapper>
          </Fade>
        </TextWrapper>
        
      </Container>
    </SectionWrapper>
  );
};

export default OnsiteBullets;
