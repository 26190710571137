import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionWrapper, { Container, ImageWrapper, TextWrapper } from './aboutUs.style';

const OnsiteBenefits = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/Home-Benefits-Onsite-Health-Services.png" }        
      ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    `);
  
  const setTitle = title => {
    return { __html: title };
  };


  return (
    <SectionWrapper id="aboutUs">
      <Container>
        
        <TextWrapper>
          <Fade right>
            <Heading as="h1" dangerouslySetInnerHTML={setTitle(`Benefits of Offering Onsite Employee Health Services in the Workplace`)} />            
            <Text content={`No matter what size of business you have, healthy employees are essential to your bottom line. 
            Employee health services such as wellness programs and onsite clinics have been proven to increase 
            employee engagement, boost mental health, encourage good health behaviors 
            and increase employee loyalty. Read on to learn more benefits of offering workplace 
            health services at your business.`} />            
          </Fade>
        </TextWrapper>
        <ImageWrapper>
          <Fade left>
          <Image
                  fluid={
                    (data.image1 !== null) | undefined
                      ? data.image1.childImageSharp.fluid
                      : {}
                  }
                  className="carousel_img"
                  alt="Feature Image 1"
                />
          </Fade>
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default OnsiteBenefits;
