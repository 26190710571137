import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import PartnerHistoryWrapper, { CounterUpArea } from './partnerHistory.style';
//import GoogleImg from 'common/src/assets/image/app/google.svg';
//import AppleImg from 'common/src/assets/image/app/apple.svg';
//import DribbleImg from 'common/src/assets/image/app/dribbble.svg';
//import MailchimpImg from 'common/src/assets/image/app/mailchimp.svg';
import BackgroundImg from 'common/src/assets/image/archerhealth/partner/Partner-bg.png';

const PartnerHistory = ({
  row,
  col,
  cardStyle,
  title,
  description,
  btnStyle,
  sectionSubTitle,
  cardArea,
}) => {
  return (
    <PartnerHistoryWrapper id="partners">
      <Image
        src={BackgroundImg}
        className="backgroungImg"
        alt="Why Archer Health"
      />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col} style={{ flexDirection: 'column' }}>
            <Text content="WHY ARCHER HEALTH" {...sectionSubTitle} />
            <FeatureBlock
              title={
                <Heading
                  content="The healthcare industry is changing rapidly. Is your company adapting?"
                  {...title}
                />
              }
              description={
                <Text
                  content="Attract talent, increase engagement, reduce absenteeism, lower healthcare spend, and prioritize employee health with modern healthcare solutions designed for today's workforce."
                  {...description}
                />
              }
              button={<Button title="WHY ARCHER HEALTH" {...btnStyle} style={{fontWeight: '500'}} click={() => window.location.href='/why-archerhealth'} />}
            />
          </Box>
          <Box className="col" {...col} {...cardArea}>
            <CounterUpArea>
              <Card className="card small-card emphatic-yellow" {...cardStyle}>
                {/* <Image src={GoogleImg} alt="Google Inc" /> */}
                <Text content="Employer healthcare costs growing at" />
                <Text content="2x" className="statistic" />
                <Text content="rate of inflation" />
              </Card>
              <Card className="card small-card emphatic-green" {...cardStyle}>
                {/* <Image src={AppleImg} alt="Apple Inc" /> */}
                <Text content="71%" className="statistic" />
                <Text content="of all ER visits are unnecessary"/>
              </Card>
              <Card className="card small-card emphatic-blue" {...cardStyle}>
                {/* <Image src={DribbleImg} alt="Dribble Inc" /> */}
                <Text content="Healthcare:" />
                <Text content="#1" className="statistic"/>
                <Text content="Benefit Sought by Candidates"/>
              </Card>
              <Card className="card small-card emphatic-violet" {...cardStyle}>
                {/* <Image src={MailchimpImg} alt="MailChimp Inc" /> */}
                <Text content="Employees" />
                <Text content="48%" className="statistic"/>
                <Text content="more likely to stay with employer with onsite healthcare"/>
              </Card>
            </CounterUpArea>
          </Box>
        </Box>
      </Container>
    </PartnerHistoryWrapper>
  );
};

PartnerHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cardStyle: PropTypes.object,
};

PartnerHistory.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  cardStyle: {
    p: '53px 40px 35px',
    borderRadius: '10px',
    boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
  },
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '48px'],
    fontWeight: '300',
    color: '#3D636C',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '490px', '490px'],
  },
  description: {
    fontSize: ['16px','18px','22px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '33px',
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#19B8E8',
    mb: '20px',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '300',
  },
  cardArea: {
    pl: [0, 0, '40px', 0, 0],
  },
};






export default PartnerHistory;
